import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PointModal from "./components/Modal/PointModal";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BookItem from "./components/BookItem/BookItem";
import "./App.css";

function App() {
  const [books, setBooks] = useState([]);
  const [stats, setStats] = useState({
    post_count: 0,
    book_count: 0,
    last_updated: "",
    oldest_post_date: "",
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 590);
  const [topTags, setTopTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [expandedBookIndex, setExpandedBookIndex] = useState(null);
  const [showScroll, setShowScroll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visibleCount, setVisibleCount] = useState(10); // 初期表示アイテム数

  const observer = useRef();

  useEffect(() => {
    fetch("https://qiita-book-leaderboard.com/api/book_ranking")
      .then((response) => response.json())
      .then((data) => setBooks(data))
      .catch((error) => console.error("Error fetching data:", error));

    fetch("https://qiita-book-leaderboard.com/api/stats")
      .then((response) => response.json())
      .then((data) => setStats(data))
      .catch((error) => console.error("Error fetching stats:", error));

    fetch("https://qiita-book-leaderboard.com/api/top_tags")
      .then((response) => response.json())
      .then((data) => setTopTags(data))
      .catch((error) => console.error("Error fetching top tags:", error));

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 590);
    };

    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  const handleTagClick = useCallback((tag) => {
    setSelectedTag(tag);
    setVisibleCount(10); // タグをクリックしたときにリセット
  }, []);

  const toggleExpand = useCallback(
    (index) => {
      setExpandedBookIndex(expandedBookIndex === index ? null : index);
    },
    [expandedBookIndex]
  );

  const filteredBooks = useMemo(() => {
    return selectedTag
      ? books.filter((book) => book.tag_names.includes(selectedTag))
      : books;
  }, [selectedTag, books]);

  const loadMoreBooks = () => {
    setVisibleCount((prevCount) => prevCount + 10); // 追加で表示するアイテム数
  };

  const lastBookElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreBooks();
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const scrollTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Header stats={stats} />
      <div className="ad-container">
        <p className="ad-message">このページには広告を含みます</p>
      </div>
      <div className="header-container">
        <h1>
          {isMobile ? (
            <>
              Qiita内で引用・紹介されている
              <br />
              技術書ランキング
            </>
          ) : (
            "Qiita内で引用・紹介されている 技術書ランキング"
          )}
        </h1>
        <div className="info-container">
          <IconButton
            aria-label="info"
            style={{ marginLeft: "10px" }}
            onClick={() => setShowModal(true)}
          >
            <InfoIcon style={{ fontSize: 30 }} />
          </IconButton>
          <span className="info-text" onClick={() => setShowModal(true)}>
            ポイントの計算方法
          </span>
        </div>
      </div>
      <div className="tags-container">
        <h2>人気のタグ</h2>
        <div className="tags-list">
          {Object.entries(topTags).map(([tag, count]) => (
            <Chip
              key={tag}
              label={tag.replace(/['"]+/g, "")}
              className="tag-item"
              onClick={() => handleTagClick(tag)}
            />
          ))}
          {selectedTag && (
            <Chip
              label="すべて表示"
              className="tag-item show-all"
              onClick={() => setSelectedTag(null)}
            />
          )}
        </div>
      </div>
      <div className="book-list-container">
        <ul className="book-list">
          {filteredBooks.slice(0, visibleCount).map((book, index) => (
            <BookItem
              key={index}
              book={book}
              index={index}
              expandedBookIndex={expandedBookIndex}
              toggleExpand={toggleExpand}
            />
          ))}
        </ul>
        {visibleCount < filteredBooks.length && (
          <div ref={lastBookElementRef} className="loading">
            Loading...
          </div>
        )}
      </div>
      <Footer />
      {showScroll && (
        <Fab
          className="scroll-top"
          onClick={scrollTop}
          color="primary"
          aria-label="scroll to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
      <PointModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        oldestPostDate={stats.oldest_post_date}
      />
    </div>
  );
}

export default App;
