import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footerContent">
        <h1 className="footerLogo">QiitaBookLeaderboard</h1>
        <p className="footerText">© 2024 QiitaBookLeaderboard Inc.</p>
      </div>
    </div>
  );
}

export default Footer;
