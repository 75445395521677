// TwitterShareButton.js
import React from "react";
import Button from "@mui/material/Button";
import XIcon from "../Icons/XIcon";
import "./ShareButtons.css";

const generateTwitterShareLink = (bookTitle, bookUrl) => {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    bookUrl
  )}&text=${encodeURIComponent(bookTitle)}`;
};

const TwitterShareButton = ({ bookTitle, bookUrl }) => {
  return (
    <Button
      variant="contained"
      className="share-button x"
      startIcon={<XIcon />}
      href={generateTwitterShareLink(bookTitle, bookUrl)}
      target="_blank"
      rel="noopener noreferrer"
    >
      Xで共有
    </Button>
  );
};

export default TwitterShareButton;
