// FacebookShareButton.js
import React from "react";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import "./ShareButtons.css";

const generateFacebookShareLink = (bookTitle, bookUrl) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    bookUrl
  )}&quote=${encodeURIComponent(bookTitle)}`;
};

const FacebookShareButton = ({ bookTitle, bookUrl }) => {
  return (
    <Button
      variant="contained"
      className="share-button facebook"
      startIcon={<FacebookIcon />}
      href={generateFacebookShareLink(bookTitle, bookUrl)}
      target="_blank"
      rel="noopener noreferrer"
    >
      Facebookで共有
    </Button>
  );
};

export default FacebookShareButton;
