import React from 'react';
import './Header.css';

function Header({ stats }) {
  return (
    <div className="header-wrapper">
      <div className="header">
        <div className='headerLeft'>
          <div className='headerLeft__object'>
            <h1 className='logo'>QiitaBookLeaderboard</h1>
          </div>
        </div>

        <div className='headerRight'>
          <div className='headerRight__object'>
            <p className="headerRight__p headerRight__p1">投稿数<br></br>{stats.post_count}</p>
          </div>
          <div className='headerRight__object'>
            <p className="headerRight__p headerRight__p2">書籍数<br></br>{stats.book_count}</p>
          </div>
          <div className='headerRight__object'>
            <p className="headerRight__p headerRight__p3">最終更新日<br></br>{stats.last_updated}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
