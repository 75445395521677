import React, { useRef, useEffect } from "react";
import "./LazyImage.css"; // 必要ならばCSSファイルもインポート

const LazyImage = ({ src, alt, className }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          imageRef.current.src = src;
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(imageRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src]);

  return (
    <img ref={imageRef} alt={alt} className={className} src="placeholder.jpg" />
  );
};

export default LazyImage;
