import React, { useEffect, useRef, useState } from 'react';
import './PointModal.css';

const PointModal = ({ show, handleClose, oldestPostDate }) => {
  const modalRef = useRef();
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setAnimateOut(true);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleAnimationEnd = () => {
    if (animateOut) {
      setAnimateOut(false);
      handleClose();
    }
  };

  return (
    <div className={`modal ${show ? "show" : ""} ${animateOut ? "hide" : ""}`} onAnimationEnd={handleAnimationEnd}>
      <div className="modal-content" ref={modalRef}>
        <span className="close-button" onClick={() => setAnimateOut(true)}>&times;</span>
        <h2>ポイントの計算方法</h2>
        <p>・投稿内で書籍名が出てきた数(同じ投稿内では1カウントまで) ✖️ 10</p>
        <p>・書籍名が出てきた投稿のストック数 ✖️ 0.5</p>
        <p>・書籍名が出てきた投稿のいいね数 ✖️ 0.5</p>
        <p>上記の合計結果でランキングを作成しています。</p>
        <p>※良い投稿(参考にした書籍も含めて)は、ストック数やいいね数も多いだろうという意図でこの計算にしています。
            <br></br>　計算結果は適宜変更する可能性もあります</p>
        <p>※Qiitaの投稿は<span className='font-weight-bold font-weight-bold-modal'>{oldestPostDate}</span>からのものを集計しています</p>
      </div>
    </div>
  );
};

export default PointModal;
