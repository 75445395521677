import React, { useRef } from "react";
import LazyImage from "../LazyImage/LazyImage";
import FacebookShareButton from "../ShareButtons/FacebookShareButton";
import TwitterShareButton from "../ShareButtons/TwitterShareButton";
import "./BookItem.css";

const BookItem = React.memo(
  ({ book, index, expandedBookIndex, toggleExpand }) => {
    const bookRef = useRef(null);

    const handleShowLess = () => {
      const top = bookRef.current.getBoundingClientRect().top;
      if (top < 0) {
        bookRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      toggleExpand(null);
    };

    const bookUrl = `https://qiita-book-leaderboard.com/book/${book.id}`; // 書籍の詳細ページのURL

    return (
      <li className="book-item" ref={bookRef}>
        <div className="book-content">
          <LazyImage
            src={book.image_url}
            alt={book.book_title}
            className="book-image"
          />
          <div className="book-info">
            <div className="book-rank-points">
              <div className="book-rank">{index + 1}</div>
              <div className="book-points">{book.points} point</div>
            </div>
            <div className="book-title">{book.book_title}</div>
            <div className="book-author">著者: {book.author}</div>
            <div className="book-date">
              出版日: {book.sales_date.replace("頃", "")}
            </div>
            <div className="share-buttons">
              <FacebookShareButton
                bookTitle={book.book_title}
                bookUrl={bookUrl}
              />
              <TwitterShareButton
                bookTitle={book.book_title}
                bookUrl={bookUrl}
              />
            </div>
            <div className="qiita-links">
              <h3>引用されているQiita記事一覧</h3>
              {Array.isArray(book.urls) &&
                book.urls.slice(0, 3).map((rakutenUrl, j) => (
                  <div key={j} className="book-url">
                    <span className="dot">•</span>
                    <a
                      href={rakutenUrl.replace(/['"]+/g, "")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {book.url_titles && book.url_titles[j]
                        ? book.url_titles[j]
                        : `Qiitaの投稿へ ${j + 1}`}
                    </a>
                  </div>
                ))}
              {Array.isArray(book.urls) && book.urls.length > 3 && (
                <div className="more-links">
                  {expandedBookIndex === index ? (
                    <>
                      {book.urls.slice(3).map((rakutenUrl, j) => (
                        <div key={j + 3} className="book-url">
                          <span className="dot">•</span>
                          <a
                            href={rakutenUrl.replace(/['"]+/g, "")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {book.url_titles && book.url_titles[j + 3]
                              ? book.url_titles[j + 3]
                              : `Qiitaの投稿へ ${j + 4}`}
                          </a>
                        </div>
                      ))}
                      <button
                        onClick={handleShowLess}
                        className="show-more"
                      >
                        タイトルを隠す
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => toggleExpand(index)}
                      className="show-more"
                    >
                      続きを表示
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="buy-buttons">
              <a href={book.amazon_link} className="buy-button">
                Amazonで購入
              </a>
              <a
                href={book.affiliateUrl.replace(/['"]+/g, "")}
                className="buy-button-rakuten"
              >
                楽天で購入
              </a>
            </div>
          </div>
        </div>
      </li>
    );
  }
);

export default BookItem;
